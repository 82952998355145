.about-section-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  padding-top: 50px;
  padding-bottom: 100px;

  p{
    text-align: justify;
    margin: auto 15vw auto 15vw;
    font-size: 1.25em;
  }
}