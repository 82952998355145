.main-section-container{
  height: 100%;
  width: 100%;
  //padding-top: 81px;
  //height: unset;
}

.main-section-moto{
  padding: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 1em;

  >div{
    background-color: var(--light-spanish);
    //box-shadow: 4px 4px 5px 3px lightgrey;
    color: white;
    height: 50px;
    border-radius: 2px;
    transform: skew(-10deg);
    display: flex;
    flex-direction: row;
    h1{
      margin: auto;
      width: 200px;
      text-align: center;
    }
  }
}

.main-section-banner-container{
  background: url("../../images/banner.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  box-shadow: 5px 8px 8px 8px lightgrey;
}

.main-section-logo{
  display: inline;
  margin: auto;
  z-index: 2;
  max-height: 200px;
  max-width: 80%
}


@media screen and (max-width:450px){

  .main-section-moto{
    >div{

      h1{
        font-size: 100%;
        margin: auto;
        width: 45vw;
        text-align: center;
      }
    }
  }

}