.navBar-container{
  position: absolute;
  top: 0px;
  height: 50px;
  width: calc(100% - 30px);
  padding: 15px;
  background-color: var(--alice-blue);
  border-bottom: solid 1px lightgray;

  .navBar-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.navBar-logo{
  width: 50px;
  height: 50px;
}

.navBar-button-container{
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  button{
    box-shadow: unset;
    background-color: unset;
    color: var(--rich-black);
    height: 50px;
    width: 125px;
    border: solid 2px transparent;
    border-radius: 2px;
    transform: skew(-10deg);
    text-align: center;
    line-height: 25px;

    &:hover{
      border: solid 2px var(--spanish-blue);
      color: var(--spanish-blue);
      background-color: transparent;
    }

    h2{
      margin: 0px;
    }
  }

  &.highlight{
    border: solid 3px var(--spanish-blue);
    border-radius: 3px;
    border-top-right-radius: 0px;
    background-color: var(--spanish-blue);

    button{
      border: unset;
      color: var(--alice-blue);
      &:hover{
        text-decoration: underline;
        color: var(--alice-blue);
      }
    }
  }
}

.navBar-menu{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1;
}

.navBar-menu-button{
  padding: 5px;
  width: 37px;
  height: 37px;
  border: solid 3px transparent;
  border-radius: 3px;

  &:hover{
    border: solid 3px var(--spanish-blue)
  }

  &.highlight{
    background-color: var(--spanish-blue);
    border: solid 3px var(--spanish-blue);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.navBar-helper{
  z-index: 2;
  width:200px;
  position: fixed;
  left:0px;
  right:0px;
  bottom:0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;

  &:hover{
    .navBar-helper-buttons{
      max-height: 500px;
      transition: max-height 0.25s ease-in;
      padding: 15px;
      border: solid 3px var(--spanish-blue);
      border-radius: 3px;
      border-top-left-radius: 0px;
      background-color: var(--spanish-blue);
    }
  }
}

.navBar-helper-up{
  margin-right: auto;
  height: 30px;
  width: 60px;
  text-align: center;
  border: solid 3px var(--spanish-blue);
  border-radius: 3px;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  background-color: var(--spanish-blue);
  color: var(--alice-blue);

  h2{
    margin: unset;
  }
}

.navBar-helper-buttons{
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  max-height: 0;
  transition: max-height 0.15s ease-out;

  button{
    background-color: var(--spanish-blue);
    box-shadow: unset;
    color: var(--alice-blue);

    &:hover{
      text-decoration: underline;
      color: var(--alice-blue);
    }
  }
}


@media screen and (max-width: 500px) {
  .navBar-button-container{
    padding: 10px;
    display: none;
    flex-direction: column;
  }
}
