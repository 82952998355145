.solutions-section-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: unset;
  max-width: 1200px;
  padding-bottom: 100px;
}

.solutions-container{
  //margin: auto 15vw auto 15vw;
  display: flex;
  gap: 100px;
}

.solution{
  box-shadow:inset 0 0 0 2000px rgba(var(--spanish-blue-rgb),0.6);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 450px;
  border-radius: 2px;
  border: solid 1px lightgray;


  &:hover{
    .solution-shutter{
      left: -130vw;
      transition: left 0.25s ease-in;
    }

    .solution-content{
      h2{
        color: white;
      }
      header{
        .dots{
          display: none;
        }
      }

      .solution-text{
        max-height: 500px;
        transition: max-height 0.25s ease-in;
      }
    }
  }
}

.solution-shutter{
  left: -50px;
  transition: left 0.25s ease-out;

  background-color: var(--alice-blue);
  height: 450px;
  width: 120vw;
  transform: skew(-10deg);
  position: absolute;
  z-index: 0;
}

.solution-content{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 450px;
  max-width: 80vw;

  header{
    display: block;

    .dots{
      font-size: 60px;
      color: var(--spanish-blue)
    }
  }

  h2{
    margin-top: 10%;
    text-align: center;
    font-size: 30px;
    color: var(--rich-black);
  }

  img{
    width: 100vw;
    object-fit: cover;
  }
}

.solution-text{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 30px;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
  border-radius: 2px;
  margin: 0px;

  .text{
    margin: 0px;
    border: solid 1px lightgray;
    border-bottom: unset;
    background-color: white;
    border-radius: 2px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 45px;
  }

  .tag{
    border: solid 1px lightgray;
    background-color: lightgray;
    border-radius: 2px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    margin: 0px;
    text-align: center;
    line-height: 30px;
    width: 30px;
    height: 30px;
  }
}


#one.solution{
  background-image: url("../../images/shutterstock_1233742984.jpg");
}

#two.solution{
  background-image: url("../../images/shutterstock_302196572.jpg");
}

#three.solution{
  background-image: url("../../images/shutterstock_620069258.jpg");
}

#four.solution{
  background-image: url("../../images/shutterstock_661113826.jpg");
}

#five.solution{
  background-image: url("../../images/shutterstock_703871917.jpg");
}


@media only screen and (max-width: 500px) {

  .solution-content{
    h2{
      font-size: 20px;
    }
  }

  .solution-text{
    .text{
      padding: 30px;
    }
  }
}