#root{
  --spanish-blue: #1a75bc;
  --spanish-blue-rgb: 26,117,188;
  --light-spanish: #27aae2;
  --rich-black: #0C161D;
  --alice-blue: #F1F5F9;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.index-container{
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;


  main{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  footer{
    width: 100%;
    height: 150px;
    grid-area: ft;
    border-top: solid 1px lightgray;
    background-color: var(--alice-blue);
  }
}

input,textarea{
  outline-color: lightgrey;

  &:focus{
    outline-color: var(--spanish-blue);
  }
}

button{
  border: 2px solid transparent;
  border-radius: 2px;
  color: white;
  background-color: grey;
  box-shadow: 0 2px 6px grey;

  &:hover{
    border: 2px solid transparent;
    border-radius: 2px;
    background-color: var(--spanish-blue);
  }

  &:active{
    background-color: var(--light-spanish);
  }
}