.loading-spinner {
  z-index: 3;
  position: fixed;
  left: auto;
  right: auto;
  bottom: 10px;
  display: inline;
  border: 7px solid lightgrey;
  border-top: 7px solid var(--light-spanish);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1.25s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
