.contact-form-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form{
  width: 90%;
  max-width: 450px;
  margin-bottom: 15px ;
  color: inherit
}

.contact-form-row{
  margin: 0.5em 0px 0.5em 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.contact-form-element{
  width: 100%;
  margin: 0px 0.5em 0px 0.5em;
  display: flex;
  flex-direction: column;

  label{
    margin: 0.25em 0px 0.25em 0px;
  }
}

.contact-form-textArea{
  font: inherit;
  resize: none;
}

.contact-form-submit{
  height: 50px;
}


input,textarea{
  padding: 10px 8px 10px 8px;
}


.contact-form-alternative{
  display: flex;
  flex-direction: column;
  width: 80vw;
  text-align: center;
  align-items: center;

  p{
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
}

.contact-form-method{
  display: flex;
  flex-direction: row;
  align-items: center;

  img{
    margin-right: 0.5em;
    width: 25px;
    height: 25px;
  }
}

@media screen and (max-width: 430px){
  .contact-form-row{
    flex-direction: column;
  }
}