section{
  display: flex;
  flex-direction: column;
  align-items: center;

  header{
    display: flex;
    justify-content: center;
    align-items: center;

    h1{
      font-size: 3em;
      transform: skew(-15deg);
    }
  }
}